<template>
  <div class="statistic">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">医学统计分析</h6>
        <div class="line"></div>
        <p class="banner_content">MEDICAL STATISTICAL ANALYSIS</p>
      </div>
    </div>
    <div class="con" style="margin-top: 50px;">
      <div class="container">
        <div class="row">
          <!-- 右侧文字列，占据8列（col-md-8表示在中等屏幕及以上宽度占据8列，在小屏幕下会占据整行） -->
          <div class="col-md-6">
            <div class="top">
              <h6 class="top_text">MEDICAL STATISTICAL METHOD</h6>
              <p class="top_content">医学统计方法</p>
              <div class="top_line"></div>
            </div>
            <div class="bottom">
              <p class="bottom_text">
                医学统计方法是认识医学现象的数量特征的重要工具，它是运用数理统计的原理和方法结合医学实际，研究医学的实验设计和数据处理。医学统计能提供各种方法，对生物现象的各种变异和影响因素进行统计分析，阐明其规律性。
              </p>
            </div>
          </div>
          <!-- 左侧图片列，占据4列（col-md-4表示在中等屏幕及以上宽度占据4列，在小屏幕下会占据整行） -->
          <div class="col-md-6">
            <img src="../assets/statisticImages/statistic1.jpg" alt="Your Image" class="img-fluid">
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <!-- 左侧图片列，占据4列（col-md-4表示在中等屏幕及以上宽度占据4列，在小屏幕下会占据整行） -->
          <div class="col-md-6">
            <img src="../assets/statisticImages/statistic2.jpg" alt="Your Image" class="img-fluid">
          </div>
          <!-- 右侧文字列，占据8列（col-md-8表示在中等屏幕及以上宽度占据8列，在小屏幕下会占据整行） -->
          <div class="col-md-6">
            <div class="top">
              <h6 class="top_text">BUSINESS SCOPE</h6>
              <p class="top_content">业务范围</p>
              <div class="top_line"></div>
            </div>
            <div class="bottom">
              <p class="bottom_text">
                统计业务包括:数据的统计分析、撰写统计分析报告，编制符合论文发表规格的图表，涉及条图、线图、直方图、散点图、箱式图茎叶图、误差条图、统计地图等。
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="statisticalMethod">
        <div>
          <div class="top">
            <h6 class="top_text">STATISTICAL METHOD</h6>
            <p class="top_content">统计方法</p>
            <div class="line"></div>
          </div>
          <div class="method">
            <div class="Methodist" v-for="(item, index) in methodsList" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row plan">
          <!-- 左侧文字列，占据4列（col-md-4表示在中等屏幕及以上宽度占据4列，在小屏幕下会占据整行） -->
          <div class="col-md-6 plan_text">
            <div class="top">
              <p class="plan_title">临床研究统计计划制定</p>
              <h6 class="plan_content">CLINICAL STUDY STATISTICAL PLANNING</h6>
            </div>
            <div class="plan_bottom ">
              <p class="bottom_text">
                完善的临床研究，必需事先制订统计计划，根据研究的PROTOCOL，以及纳入人群的特征，预估可能的数据特征，提出合理的统计计划。统计计划主要用于随机对照临床试验，前瞻性队列研究以及登记数据库中。
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <img src="../assets/statisticImages/plan_bottom.jpg" alt="Your Image" class="img-fluid">
          </div>
          <!-- 右侧图片列，占据8列（col-md-8表示在中等屏幕及以上宽度占据8列，在小屏幕下会占据整行） -->

        </div>
      </div>
      <div class="container container_two ">
        <img src="../assets/statisticImages/ONE.jpg" alt="">
        <div>
          <h4>统计咨询</h4>
          <p class="bottom_text">涵盖统计分析的绝大部分常见领域，为客户提供专业的统计方案咨询和统计方法及统计软件的指导。</p>
        </div>
      </div>
      <div class="container container_two ">
        <img src="../assets/statisticImages/TWo.jpg" alt="">
        <div>
          <h4>临床研究统计方案指导</h4>
          <p class="bottom_text">统计学贯穿于临床研究的整个阶段:试验设计(样本量确定和随机分组)、数据管理、统计分析计划制定、试验数据处理和统计分析报告。统计分析报告用于科学论文的撰写，只有进行科学合理的统计分析，才能根据临床研究得出正确可靠的结论。</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      methodsList: [
        {
          name: '多因素线性回归'
        },
        {
          name: '多因素逻辑回归'
        },
        {
          name: '多因素COX回归'
        },
        {
          name: '交叉滞后模型'
        },
        {
          name: '比例优势回归'
        },
        {
          name: '负二项回归'
        },
        {
          name: '析因分析'
        },
        {
          name: '聚类分析'
        },
        {
          name: '因子分析'
        },
        {
          name: '孟德尔随机化'
        },
        {
          name: '因果中介分析'
        },
        {
          name: '限制性立方样条'
        },
        {
          name: '广义估计方程'
        },
        {
          name: '广义线性模型'
        },
        {
          name: '混合效应模型'
        },
        {
          name: '增长混合模型'
        },
        {
          name: '潜增长曲线模型'
        },
        {
          name: '结构方程模型'
        },
        {
          name: '倾向性评分匹配'
        },
        {
          name: '逆改率加权法'
        },
        {
          name: '泊松回归'
        }
        
      ]
    }
  },
}
</script>
<style scoped lang="less">
.bottom_text {
  font-size: 16px;
}

// .method {
//   height: 280px;
//   width: 1370px;
//   display: flex;
//   justify-content: flex-start;
//   flex-wrap: wrap;
//   margin: auto;
// }

.Methodist {
  border-radius: 5px;
  margin: 4px;
  width: calc(100% / 7 - 8px);
  /* 一行显示七个 */
  height: 89px;
  text-align: center;
  border: 1px solid #0D80FF;
  line-height: 89px;
  font-size: 14px;
  color: #0D80FF;
}

/* 移动端样式 */
@media screen and (max-width: 767px) {
  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/statisticImages/Statistic-bannerMobile.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .method {
    font-size: 12px;
    height: 690px;
    width: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
  }

  .Methodist {
    width: calc(100% / 3 - 8px);
    background-color: #fff;
  }

  .top {
    text-align: center !important;
    margin-top: 30px;

    .top_text {
      font-size: 22px;
      color: #999999;
    }

    .top_content {
      font-size: 22px;
      color: #010101;
    }

    .line {
      position: relative;
      top: 2%;
      left: 49%;
      width: 22px;
      height: 2px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // 线条粗细
      border: 1px solid black;
      margin: 24px 0px;
    }

    .top_line {
      width: 22px;
      height: 2px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // 线条粗细
      border: 1px solid black;
      margin: 24px auto;
    }
  }

  .plan_text {
    .top {
      text-align: center;
    }

    .plan_title {
      font-size: 22px;
      color: #010101;
    }

    .plan_content {
      font-size: 22px;
    }

    .plan_bottom {
      margin: 35px 0px;
      text-align: center;
      font-size: 16px;
    }
  }

  .container_two {
    margin-top: 20px;
    display: flex;
    font-size: 14px;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .container_two img {
    width: 120px;
    height: 120px;
    margin-right: 10px;
    border: 1px dashed black;
  }

  .container div {
    text-align: left;
  }

}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .banner {
    width: 100%;
    height: 600px;
    background-image: url(../assets/statisticImages/Statistic-banner.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333;
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .top {
    .top_text {
      font-size: 36px;
      color: #999999;
    }

    .top_content {
      font-size: 30px;
      color: #010101;
    }

    .top_line {
      width: 25px;
      height: 2px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // 线条粗细
      border: 1px solid black;
      margin: 24px auto;
    }
  }

  .statistic {
    height: 2720px;

    .statisticalMethod {
      text-align: center;
      height: 594px;
      background-color: #F7FBFE;


      .line {
        position: relative;
        top: 2%;
        left: 49%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }
       .method {
      height: 280px;
      width: 1370px;
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      margin: auto;

      .Methodist {
        border-radius: 5px;
        margin: 2px;
        width: 190px;
        height: 89px;
        text-align: center;
        border: 1px solid #0D80FF;
        line-height: 89px;
        font-size: 18px;
        color: #0D80FF;
      }

    }
    }

    .top_line {
      width: 25px;
      height: 2px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      // 线条粗细
      border: 1px solid black;
      margin: 24px 0px;
    }

    img {
      border: 1px dashed #999999;
    }

   

    .plan {
      margin-top: 55px;

      .plan_text {
        margin-top: 65px;

        .plan_title {
          font-size: 30px;
          color: #010101;
        }

        .plan_content {
          font-size: 18px;
        }

        .plan_bottom {
          margin: 35px 0px;
        }
      }
    }

    .container_two {
      display: flex;
      margin-top: 25px;
    }

    .container {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
    }

    .container img {
      margin-right: 20px;
    }

    .container div {
      text-align: left;
    }
  }

}
</style >
  