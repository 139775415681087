var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistic"},[_vm._m(0),_c('div',{staticClass:"con",staticStyle:{"margin-top":"50px"}},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"statisticalMethod"},[_c('div',[_vm._m(3),_c('div',{staticClass:"method"},_vm._l((_vm.methodsList),function(item,index){return _c('div',{key:index,staticClass:"Methodist"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])]),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"banner_text"},[_c('h6',{staticClass:"banner_title"},[_vm._v("医学统计分析")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"banner_content"},[_vm._v("MEDICAL STATISTICAL ANALYSIS")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"top"},[_c('h6',{staticClass:"top_text"},[_vm._v("MEDICAL STATISTICAL METHOD")]),_c('p',{staticClass:"top_content"},[_vm._v("医学统计方法")]),_c('div',{staticClass:"top_line"})]),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"bottom_text"},[_vm._v(" 医学统计方法是认识医学现象的数量特征的重要工具，它是运用数理统计的原理和方法结合医学实际，研究医学的实验设计和数据处理。医学统计能提供各种方法，对生物现象的各种变异和影响因素进行统计分析，阐明其规律性。 ")])])]),_c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/statisticImages/statistic1.jpg"),"alt":"Your Image"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/statisticImages/statistic2.jpg"),"alt":"Your Image"}})]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"top"},[_c('h6',{staticClass:"top_text"},[_vm._v("BUSINESS SCOPE")]),_c('p',{staticClass:"top_content"},[_vm._v("业务范围")]),_c('div',{staticClass:"top_line"})]),_c('div',{staticClass:"bottom"},[_c('p',{staticClass:"bottom_text"},[_vm._v(" 统计业务包括:数据的统计分析、撰写统计分析报告，编制符合论文发表规格的图表，涉及条图、线图、直方图、散点图、箱式图茎叶图、误差条图、统计地图等。 ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('h6',{staticClass:"top_text"},[_vm._v("STATISTICAL METHOD")]),_c('p',{staticClass:"top_content"},[_vm._v("统计方法")]),_c('div',{staticClass:"line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row plan"},[_c('div',{staticClass:"col-md-6 plan_text"},[_c('div',{staticClass:"top"},[_c('p',{staticClass:"plan_title"},[_vm._v("临床研究统计计划制定")]),_c('h6',{staticClass:"plan_content"},[_vm._v("CLINICAL STUDY STATISTICAL PLANNING")])]),_c('div',{staticClass:"plan_bottom"},[_c('p',{staticClass:"bottom_text"},[_vm._v(" 完善的临床研究，必需事先制订统计计划，根据研究的PROTOCOL，以及纳入人群的特征，预估可能的数据特征，提出合理的统计计划。统计计划主要用于随机对照临床试验，前瞻性队列研究以及登记数据库中。 ")])])]),_c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/statisticImages/plan_bottom.jpg"),"alt":"Your Image"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container container_two"},[_c('img',{attrs:{"src":require("../assets/statisticImages/ONE.jpg"),"alt":""}}),_c('div',[_c('h4',[_vm._v("统计咨询")]),_c('p',{staticClass:"bottom_text"},[_vm._v("涵盖统计分析的绝大部分常见领域，为客户提供专业的统计方案咨询和统计方法及统计软件的指导。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container container_two"},[_c('img',{attrs:{"src":require("../assets/statisticImages/TWo.jpg"),"alt":""}}),_c('div',[_c('h4',[_vm._v("临床研究统计方案指导")]),_c('p',{staticClass:"bottom_text"},[_vm._v("统计学贯穿于临床研究的整个阶段:试验设计(样本量确定和随机分组)、数据管理、统计分析计划制定、试验数据处理和统计分析报告。统计分析报告用于科学论文的撰写，只有进行科学合理的统计分析，才能根据临床研究得出正确可靠的结论。")])])])
}]

export { render, staticRenderFns }